import { render, staticRenderFns } from "./NewMilestone.vue?vue&type=template&id=4ceea11b&scoped=true"
import script from "./NewMilestone.vue?vue&type=script&lang=js"
export * from "./NewMilestone.vue?vue&type=script&lang=js"
import style0 from "./NewMilestone.vue?vue&type=style&index=0&id=4ceea11b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ceea11b",
  null
  
)

export default component.exports